import Vue from 'vue'
import Vuex from 'vuex'
import Global from './modules/global'
import User from './modules/user'
import Questionnaire from './modules/questionnaire'
import Addresses from './modules/address'
import Employment from './modules/employment'
import MultipleChoice from "./modules/multiplechoice"
import { EncryptStorage } from "encrypt-storage";
import createPersistedState from "vuex-persistedstate"

Vue.use(Vuex)

const encryptStorage = new EncryptStorage("your-secret-key", {
    storageType: "sessionStorage",
    stateManagementUse: true,
});

export default new Vuex.Store({
    modules: { Global, User, Questionnaire, Addresses, Employment, MultipleChoice },
    plugins: [createPersistedState({
        paths: ['Global', 'User', 'Questionnaire', 'Addresses', 'Employment', 'MultipleChoice'],
        storage: {
            getItem: (key) => {
                try {
                    return encryptStorage.getItem(key) ?? null;
                } catch (error) {
                    console.error("Error retrieving data:", error);
                    return null;
                }
            },
            setItem: (key, value) => {
                try {
                    encryptStorage.setItem(key, value);
                } catch (error) {
                    console.error("Error setting data:", error);
                }
            },
            removeItem: (key) => {
                try {
                    encryptStorage.removeItem(key);
                } catch (error) {
                    console.error("Error removing data:", error);
                }
            },
        },
    })],
})
const defaultState = () => {
    return {
        appFlow: "",
        orgId: "",
        prospectId: "",
        companyName: "",
        isCoPilot: false,
        coPilotEmail: '',
        appAssets: {},
        forgotPassDetails: {},
        setupPassword: true,
        loaderStatus: false,
        isNavigating : false,
        compLoaderStatus: false,
        appLanguage: "English",
        coPilotSession: false
    }
}

const state = defaultState();

const getters = {
    getAppFlow: state => state.appFlow,
    getOrgId: state => state.orgId,
    getProspectId: state => state.prospectId,
    getCompanyName: state => state.companyName,
    getIsCoPilot: state => state.isCoPilot,
    getCoPilotEmail: state => state.coPilotEmail,
    getCoPilotSession: state => state.coPilotSession,
    getAssets: state => state.appAssets,
    getForgotPassDetails: state => state.forgotPassDetails,
    getSetupPassword: state => state.setupPassword,
    getLoaderStatus: state => state.loaderStatus,
    getIsnavigting: state => state.isNavigating,
    getCompLoaderStatus: state => state.compLoaderStatus,
    getAppLanguage: state => state.appLanguage
};

const actions = {
    fetchAppFlow({ commit }, data) {
        commit('setAppFlow', data);
    },

    fetchOrgId({ commit }, data) {
        commit('setOrgId', data);
    },

    fetchProspectId({ commit }, data) {
        commit('setProspectId', data);
    },

    fetchCompanyName({ commit }, data) {
        commit('setCompanyName', data);
    },

    fetchIsCoPilot({ commit }, data) {
        commit('setIsCoPilot', data);
    },

    fetchCoPilotEmail({ commit }, data) {
        commit('setCoPilotEmail', data);
    },
    fetchCoPilotSession({ commit }, data) {
        commit('setCoPilotSession', data);
    },

    fetchAssets({ commit }, data) {
        commit('setAssets', data);
    },

    fetchForgotPassDetails({ commit }, data) {
        commit('setForgotPassDetails', data);
    },

    fetchSetupPassword({ commit }, data) {
        commit('setSetupPassword', data);
    },

    fetchLoaderStatus({ commit }, data) {
        commit('setLoaderStatus', data);
    },
    fetchIsNavigating({ commit }, data) {
        commit('setIsNavigating', data);
    },

    fetchCompLoaderStatus({ commit }, data) {
        commit('setCompLoaderStatus', data);
    },

    fetchAppLanguage({ commit }, data) {
        commit('setAppLanguage', data);
    },

    resetGlobalStore({ commit }) {
        commit('resetGlobalState')
    }
};

const mutations = {
    setAppFlow: (state, appflow) => state.appFlow = appflow,
    setOrgId: (state, orgid) => state.orgId = orgid,
    setProspectId: (state, prospectid) => state.prospectId = prospectid,
    setCompanyName: (state, company) => state.companyName = company,
    setIsCoPilot: (state, status) => state.isCoPilot = status,
    setCoPilotEmail: (state, email) => state.coPilotEmail = email,
    setCoPilotSession: (state, session) => state.coPilotSession = session,
    setAssets: (state, assets) => state.appAssets = assets,
    setForgotPassDetails: (state, details) => state.forgotPassDetails = details,
    setSetupPassword: (state, status) => state.setupPassword = status,
    setLoaderStatus: (state, loader) => state.loaderStatus = loader,
    setIsNavigating: (state, naviagteStatus) => state.isNavigating = naviagteStatus,
    setCompLoaderStatus: (state, comploader) => state.compLoaderStatus = comploader,
    setAppLanguage: (state, language) => state.appLanguage = language,

    resetGlobalState(state) {
        Object.assign(state, defaultState())
    }
};

export default {
    state,
    getters,
    actions,
    mutations
}
import Vue from 'vue'
import App from './App.vue'
import VueRouter from 'vue-router'
import Vuelidate from 'vuelidate'
import axios from './api-service'
import routes from './routes'
import store from './store'
import encryptStorage from './secure-storage'
import Toast from 'vue-toastification'
import GAuth from 'vue-google-oauth2'
import Toasters from './mixins/toasters'
import "vue-toastification/dist/index.css"

const googleAuthOptions = {
  clientId: '852790099594-7o72moq1rn37uam6m5ii2ehaibc8k7vj.apps.googleusercontent.com',
  scope: 'profile email',
  prompt: 'consent',
  fetch_basic_profile: true
}

Vue.use(VueRouter)
Vue.use(Vuelidate)
Vue.use(GAuth, googleAuthOptions)
Vue.use(Toast, {
  transition: "Vue-Toastification__fade",
  maxToasts: 8
})

Vue.mixin(Toasters)

const scrollBehavior = () => {
  return {
    x: 0,
    y: 0
  }
}

const router = new VueRouter({
  routes,
  scrollBehavior,
  mode: 'history'
});

router.beforeEach((to, from, next) => {
  let authenticated = store.getters.getIsAuthenticated;
  let companyName = store.getters.getCompanyName;
  let prospectId = store.getters.getProspectId;
  let orgId = store.getters.getOrgId;

  if (to.matched.some(record => record.meta.requiresAuth)) {
    // this route requires auth, check if logged in
    // if not, redirect to login page.
    if (!authenticated) {
      next({ path: '/' + companyName + '/' + prospectId + '&' + orgId })
    } else {
      next(); // go to wherever I'm going
    }
  } else {
    next(); // does not require auth, make sure to always call next()!
  }
});

Vue.config.devtools = true
Vue.config.productionTip = false

Vue.prototype.$http = axios
Vue.prototype.$localStore = encryptStorage;

new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app')
